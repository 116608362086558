<template>
  <div class="UserHome">
    <div class="bottomDiv1" style="height: auto; margin-bottom: 15px;" >
      <div class="index_title" style="border-bottom: none">设备管理</div>
      <div class="list1" style="font-size: 16px;">
        <router-link class="list2" to="/mobile_index/device_manage"><p>充电站</p><p>{{ total }}</p></router-link>
        <router-link class="list2" to="/mobile_index/charg_manage"><p>设备</p><p>{{ total1 }}</p></router-link>
      </div>
      
    </div>
    <div class="bottomDiv1" style="height: auto;">
      <div class="index_title" style="border-bottom: none">充电站列表</div>
      <div>
          <el-form  style="width:50vw; margin-left: 5vw;">
            <el-select v-model="FormData.type_id" placeholder="请选择充电车型">
              <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
            </el-select>
          </el-form>
          <el-form label-position="left" style="width: 76vw; display: flex; justify-content: space-between;">
            <el-form-item style="width:50vw; margin-left: 5vw;">
              <el-input
                v-model="FormData.device_id"
                placeholder="请输入设备编号"
              ></el-input>
            </el-form-item>
        
            <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
      <el-table
          stripe="true"
      ref="multipleTableRef"
        :data="tableData"
        border 
        v-loading="loading"
        height="480px"
        style="
          width: 90%;
          margin: 0 auto;
          overflow-y: auto;
        "
      >
          <el-table-column label="设备编号" prop="device_id" />
          <!-- <el-table-column label="站点名称" prop="station_name"/>
          <el-table-column label="设备类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <el-table-column label="生产厂商" prop="manufacturer" />
          <el-table-column label="规格类型" prop="Specification_type"/>
          <el-table-column label="所属省" prop="province"/>
          <el-table-column label="所属市" prop="city"/>
          <el-table-column label="所属县区" prop="county"/>
          <el-table-column label="详细地址" prop="community" width="150" />
          <el-table-column label="功率" prop="wx_id" />
          <el-table-column label="计费模板" prop="price_id" />
          <el-table-column label="开通时间" prop="opening_time" />
          <el-table-column label="安装时间" prop="opening_time" />
          <el-table-column label="工作状态" prop="state" />
          <el-table-column label="运营商" prop="username" /> -->
          <el-table-column
            label="详情"
            align="center"
          >
            <template #default="scope" >
              <div style="float: left;">
                <div class="BtnSearch" @click="todetal(scope.$index, scope.row)">详情</div>
              </div>
              
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120"
            align="center"
          > 
            <template #default="scope">
              <!-- <div class="BtnSearch1 BtnSearch" @click="openCharg(scope.$index, scope.row)" >
                开启充电
              </div>
              <div class="BtnSearch1 BtnSearch" @click="offCharg(scope.$index, scope.row)" >
                关闭充电
              </div> -->
             
              <div
                class="BtnSearch1 BtnSearch"
                @click="handleDel(scope.$index, scope.row)"
                >删除</div
              >
              <!-- <div
                class="BtnSearch1 BtnSearch"
                style="width:100px; margin-top: 2px;"
                @click="handleerwm(scope.$index, scope.row)"
                >下发二维码</div
              > -->
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="pagination">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[5, 10, 15, 20]"
            :small="small"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
      </div> -->
      </div>
      <!-- 详情 -->
      <el-dialog
            v-model="Dialog3.editLevy"
            title="设备详情"
            width="80%"
            :before-close="closeDialog3"
            class="dialog"
          >
            <div class="formDiv1">
              <el-form
                label-position="right"
                label-width="100px"
                style="font-size: 16px;"
              >
                <p>设备编号: {{ Dialog3.DialogData.device_id }}</p>
                <p>充电站: {{ Dialog3.DialogData.station_name }}</p>
                <p>省: {{ Dialog3.DialogData.province }}</p>
                <p>市: {{ Dialog3.DialogData.city }}</p>
                <p>县/区: {{ Dialog3.DialogData.county }}</p>
                <p>详细地址: {{ Dialog.DialogData.community }}</p>
                <p>计费模板: {{ Dialog3.DialogData.price_name }}</p>
                <p>充电类型: {{ Dialog3.DialogData.type_id == '2'?'电动车':'电动汽车' }}</p>
                <p>端口A状态: {{ Dialog3.DialogData.porta}}</p>
                <p>端口B状态: {{ Dialog3.DialogData.portb }}</p>
                <p>网络状态: {{ Dialog3.DialogData.device_state}}</p>
              
              
              </el-form>
            </div>
          </el-dialog>

    </div>
  </div>
</template>
<script>
// import { ref } from 'vue'
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_device,upd_device,stop_charge,qr_code,sel_station,remove_device,operator_data } from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
import ProvinceCityCountry from '../../assets/json/address'
import {  regionData } from 'element-china-area-data'
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        operator_id : sessionStorage.getItem('userID'),
        // actionTime:['','']
      },
      isadmin:false,
      users:[],
      tableData: [
      {
          serial_number:"YH001",
          user_name:"张三"
        },
      ],
      carList:[
          {
            value:2,
            label:'电动车'
          },
          {
            value:4,
            label:'电动汽车'
          }
        ],
        car:'',
      provinceData: ProvinceCityCountry.address,
      // options: regionData,
      // selectedOptions: "",
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: '',
      city: '',
      county: '',
      
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      Dialog3: {
        editLevy: false,
        DialogData: {},
      },
    
      loading:false,
      //   分页
      currentPage:1,
      pageSize:20,
      total:0,
      total1:0,
      checked:0,
      small:true,
      stations2:[],
      stations4:[],
      type_id2:false,
      type_id4:false,
      Specifs:[
        {
          value:0,
          label:'单路充电'
        },
        {
          value:1,
          label:'双路充电'
        }
      ]

    });
    const searchBtn = () => {
      
      data.loading = true;
      sel_device(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getList = () => {
      
      data.loading = true;
      sel_station(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });

      sel_device(data.FormData).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total1 = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    
    const todetal = (index,row) => {

      data.Dialog3.editLevy = true;
      data.Dialog3.DialogData = row;
    }

    const offCharg = (index, row) => {
      const dataa = {
        device_id : row.device_id,
        
      }
      stop_charge(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("已开启充电")
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const handleEdit = (index, row) => {
      
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
      if(row.type_id == 2){
        data.type_id2 = true
        data.type_id4 = false
      }else if(row.type_id == 4){
        data.type_id4 = true
        data.type_id2 = false
      }
    };
    const handleerwm = (index, row) => {
      const dataa = {
        pile_crc : row.device_id
      }
      qr_code(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("下发成功")
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("下发失败");
        }
      });
    };

    const handleDel = (index, row) => {
      const dataa = {
        id : row.id
      }
      ElMessageBox.confirm("您确认要删除设备吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
      remove_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("删除成功")
            getList()
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("删除失败");
        }
      });
      }).catch(()=>{
        
      })
    };

    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const closeDialog3 = () => {
      data.Dialog3.editLevy = false;
    };
  

    const submitDialog = () => {
      const dataa = data.Dialog.DialogData
      upd_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("编辑成功")
            submitData();
            getList()
            data.Dialog.editLevy = false;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
      
    };



   
    const submitData = () => {
   
    };
    

    const getStation2 = ()=>{
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
        type_id:2
      }
      sel_station(dataa).then((res)=>{
        if (res) {
          data.loading = false
          if (res.code == 200) {
            data.stations2 = res.data
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("充电站获取失败");
        }
      })
    }

    const getStation4 = ()=>{
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
        type_id:4
      }
      sel_station(dataa).then((res)=>{
        if (res) {
          data.loading = false
          if (res.code == 200) {
            data.stations4 = res.data
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("充电站获取失败");
        }
      })
    }

    const getUsername = () => {
      const username1 = localStorage.getItem('username')
      const users1 = []
      operator_data().then((res) => {
        console.log(res,'sssssss');
        data.users = res
        res.forEach((a)=>{    
          users1.push(a.username)
      })
      if(users1.indexOf(username1)==-1){
        data.isadmin = true
      }else{
        data.isadmin = false
        data.FormData.username = username1
      }
      });
    };



    const handleChange = (value) => {
    }

    const handleSizeChange = (val) => {
      data.pageSize = val
    }
    const handleCurrentChange = (val) => {
      data.currentPage = val
      // 
    }



    const initProvinceList =()=> {
      
      // 初始化省份列表数据源    
      regionData.forEach((tab) => {
            data.provinceList.push(tab)
          });

    }
    const handleProvinceChange = (val) =>{
      data.cityList=[]
      data.countyList=[]
      data.province = val
      data.FormData.city = ''
      data.FormData.county = ''
       initCityList()
       
    }

    
    const initCityList=()=> {
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1)=>{
        if(tab1.label==data.province){
          data.cityList.push(tab1.children)
        }
      })
  }

  const handleCityChange=(value1)=>{
    data.countyList=[]
    data.city = value1
    data.FormData.county = ''
       initCountyList()
    }

    const initCountyList=()=> {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2)=>{
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if(tab2.label==data.city){
          data.countyList.push(tab2.children)
        }
      })
    }


    

    onMounted(() => {
      getList();
      getUsername();
      getStation2();
      getStation4();
      initProvinceList()
    });
    return {
      ...toRefs(data),
      searchBtn,
      offCharg,
      handleEdit,
      handleerwm,
      handleDel,
      closeDialog,
      todetal,
      closeDialog3,
      submitDialog,
      submitData,
      getList,
      getStation2,
      getStation4,
      handleChange,
      handleSizeChange,
      handleCurrentChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      getUsername
    };
  },
};
</script>
<style scoped>
.bottomDiv1 {
  background-color: #fff;
  padding: 0.5rem;
  font-size: 18px;
}
.formDiv {
  margin-top: 10px;
  height: 120px;
}
.formDiv1 {
  margin-top: -20px;
  padding: 2%;
}
.formDiv1  p{

  /* height: 30px; */
  line-height: 30px;
  /* border-bottom: 1px solid lightblue; */
  /* margin: 0.2rem; */
}
.bottomDiv {
    height: 100%;
  }

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1{
  float: left;
  width: 80px;
  margin: 0 4px;
}

.pagination{
    display: flex;
    justify-content: space-between;
    padding: 0 67px;
    /* margin-top: -10px; */
}

.list1{
  padding: 5px;

}
.list2{
  color: black;
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid lightblue;
}
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
</style>
